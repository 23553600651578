<template>
  <div>
    <skeleton-table v-if="initialize" :rows="10" :columns="fields.length" :buttons="1" :dateSearch="true" />

    <no-records-found
      v-if="showNotFound"
      text="No transaction history found in your billing records"
      btnOneLink="buy-credits"
    >
      <template #btnOne>
        <feather-icon icon="DollarSignIcon" class="mr-50" />
        <span class="align-middle">Buy Credits</span>
      </template>
    </no-records-found>

    <b-card v-else no-body class="action-buttons" :class="{'d-none': mainCardInvisiblity}">
      <b-row class="mb-1 mx-1">
        <b-col md="6">
          <div class="inline-spacing">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mr-50 mt-2"
              @click="downloadTransactions"
              :disabled="downloadIsLoading"
              :class="{ disabled: downloadIsLoading }"
            >
              <b-spinner small v-if="downloadIsLoading" />
              &nbsp;
              <feather-icon icon="DownloadIcon" class="mr-50" />
              <span class="align-middle">Download</span>
            </b-button>
          </div>
        </b-col>

        <b-col md="6" class="mt-2">
          <!-- basic -->
          <b-form-group>
            <b-input-group>
              <b-form-datepicker
                v-model="dateFrom"
                :max="maxDate"
                placeholder="Date from"
                reset-button
                class="rounded"
                locale="en"
              />
              <b-form-datepicker
                v-model="dateTo"
                :max="maxDate"
                placeholder="Date to"
                reset-button
                class="ml-2 rounded"
                locale="en"
              />

              <b-input-group-append>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon rounded-circle ml-1"
                  @click="transactionRefresh"
                >
                  <feather-icon icon="SearchIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <small v-if="dateError" class="text-danger"
              >Both of these fields are required for date range</small
            >
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        show-empty
        empty-text="No records found from your search"
        empty-filtered-text="No records found from your search"
        hover
        striped
        :busy.sync="isBusy"
        :current-page="currentPage"
        :per-page="perPage"
        responsive
        :items="transactions"
        :fields="fields"
        ref="transactionTable"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(amount)="data"> KSH {{ data.item.amount }} </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        v-if="!noRecordsFound"
      ></b-pagination>
    </b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BTable,
  BButton,
  BSpinner,
  BCard,
  BFormDatepicker,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BCol,
  BRow,
} from "bootstrap-vue";
import NoRecordsFound from "@/views/pages/no-records-found/NoRecordsFound.vue";
import Ripple from "vue-ripple-directive";
import SkeletonTable from "@/views/pages/shimmers/SkeletonTable.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BTable,
    BPagination,
    BCard,
    BButton,
    BSpinner,
    BFormDatepicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    BRow,
    NoRecordsFound,
    SkeletonTable,
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      fields: [
        {
          key: "index",
          label: "#",
        },
        {
          key: "amount",
          label: "TRANSACTION AMOUNT",
        },
        {
          key: "sms_units",
          label: "SMS UNITS",
        },
        {
          key: "mode",
          label: "MODE OF PAYMENT",
        },
        {
          key: "format_created_at",
          label: "TRANSACTION DATE",
        },
      ],
      maxDate: new Date(today),
      dateFrom: null,
      dateTo: null,
      dateError: false,
      downloadIsLoading: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      initialize: true,
      showNotFound: false,
      noRecordsFound: false,
      mainCardInvisiblity:true,
    };
  },
  methods: {
    transactions(ctx, callback) {
      let params;
      if (this.dateFrom && this.dateTo) {
        params =
          "?page=" +
          ctx.currentPage +
          "&date_from=" +
          this.dateFrom +
          "&date_to=" +
          this.dateTo;
      } else {
        params = "?page=" + ctx.currentPage;
      }
      this.$http
        .get("/credits/transaction-history" + params)
        .then((response) => {
          this.totalRows = response.data.total_pages * 10;
          // Pluck the array of items off our axios response
          const items = response.data.data;
          if (items.length == 0) {
            this.noRecordsFound = true;

            if (this.initialize) {
              this.showNotFound = true;
            }
          } else {
            this.noRecordsFound = false;
          }
          this.initialize = false;
          this.isBusy = false;
          this.mainCardInvisiblity = false;
          callback(items);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          if (this.initialize) {
            this.showNotFound = true;
          }
          this.noRecordsFound = true;
          this.initialize = false;
          this.mainCardInvisiblity = false;
          callback([]);
          this.isBusy = false;
        });
      return null;
    },
    downloadTransactions() {
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
        return;
      }
      let params = "";
      if (this.dateFrom && this.dateTo) {
        params = "?date_from=" + this.dateFrom + "&date_to=" + this.dateTo;
      }
      this.downloadIsLoading = true;
      this.$http
        .get("/credits/transaction-history/download" + params, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "transactions-history-download.xlsx");
          document.body.appendChild(link);
          link.click();
          setTimeout(() => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Transaction History Downloaded",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.downloadIsLoading = false;
            this.dateError = false;
          }, 1000);
        })
        .catch((error) => {
          for (let err of error.response.data.error) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: err,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          }
          this.downloadIsLoading = false;
          this.dateError = false;
        });
    },
    transactionRefresh() {
      if ((!this.dateFrom && this.dateTo) || (this.dateFrom && !this.dateTo)) {
        this.dateError = true;
      } else {
        this.dateError = false;
        this.$refs.transactionTable.refresh();
      }
    },
  },
};
</script>
